import { useEffect, useState } from "react";
import { appFetch } from "../api/appFetch";

export const useFiles = (key, url) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!!localStorage.getItem(key)) {
      setFiles(JSON.parse(localStorage.getItem(key)));
      return;
    }
    const getFilesFn = async () => {
      const response = await appFetch(url, "GET", null);
      setFiles(response.contents);
      localStorage.setItem(key, JSON.stringify(response.contents));
    };

    getFilesFn();
  }, [key, url]);

  return files;
};
