import { authorize } from "./authorize";

export const appFetch = async (url, method, body) => {
  const token = await authorize();

  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: body ? JSON.stringify(body) : null,
    });

    return await response.json();
  } catch (error) {
    console.error("Error during fetch:", error.message);
    throw error;
  }
};
