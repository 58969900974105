import React, { useEffect } from "react";

function AudioPlayer(props) {
  const { audioRef } = props;

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener("canplaythrough", () => {
      audioElement.play();
    });

    return () => {
      audioElement.removeEventListener("canplaythrough", () => {
        audioElement.play();
      });
    };
  }, [audioRef]);

  return (
    <audio ref={audioRef} controls style={{ display: "none" }}>
      <source src={`${process.env.PUBLIC_URL}/beyonce.mp3`} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
}

export default AudioPlayer;
