import React, { useRef, useState } from "react";

import { WishOverlay } from "./WishOverlay";
import { Assets } from "./Assets";
import { Reasons } from "./Reasons";
import { Ending } from "./Ending";
import "./App.css";
import AudioPlayer from "./AudioPlayer";

export const App = () => {
  const audioRef = useRef(null);
  const [hasWished, setHasWished] = useState(false);

  if (!hasWished) return <WishOverlay complete={() => setHasWished(true)} />;

  return (
    <div>
      <Assets />
      <Reasons />
      <Ending audioRef={audioRef} />
      <AudioPlayer audioRef={audioRef} />
    </div>
  );
};
