import { useRef } from "react";
import "./Reasons.css";
import { useIsVisible } from "./hooks/useIsVisible";

export const Reasons = () => {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  const reasonsToLove = [
    "добричка си",
    "умна си",
    "красива си",
    "имаш готин хумор",
    "секси си",
    "мила си",
    "съобразителна си",
    "внимателна си",
    "подкрепяш ме",
    "вярваш в мен",
    "имаш красиви очи",
    "имаш красива усмивка",
    "имаш красива коса",
    "имаш стил",
    "имаш характер",
    "имаш мечти",
  ];

  return (
    <div className="reasons-wrapper" ref={ref}>
      {isVisible && (
        <div className="reasons">
          <h2 className="heading">Причини да те обичам</h2>
          {reasonsToLove.map((reason, idx) => (
            <p
              key={reason}
              className={`reason ${idx % 2 === 0 ? "left-tilt" : "right-tilt"}`}
            >
              {reason}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
