import { useState } from "react";
import "./WishOverlay.css";

export const WishOverlay = (props) => {
  const [isWishOpen, setIsWishOpen] = useState(false);

  const handleOpenWish = () => {
    setIsWishOpen(true);
  };

  const mainText = isWishOpen
    ? "Пожелавам ти да сме силни и щастливи заедно и да имаме късмет, да се обичаме и да имаме всичко, което си пожелаем! А аз ще се постарая да осъществя това за теб!"
    : "Честит рожден ден, любов!";

  return (
    <>
      <div
        style={{
          zIndex: "999",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          backgroundColor: "#fff",
        }}
      >
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
      </div>
      <div className="main-content">
        {!isWishOpen && (
          <button className="button" onClick={handleOpenWish}>
            Отвори пожелание
          </button>
        )}
        {isWishOpen && (
          <button className="button" onClick={props.complete}>
            Нашите моменти
          </button>
        )}
        <p className={isWishOpen ? "wish-text" : "heading"}>{mainText}</p>
      </div>
      <div></div>
      <div
        style={{
          zIndex: "999",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#fff",
        }}
      >
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
        <img src="/hearts.gif" alt="hearts" />
      </div>
    </>
  );
};
