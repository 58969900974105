const sirvCredentials = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
};

/**
 * returns a json object with the access token
 */
export const authorize = async () => {
  const response = await fetch("https://api.sirv.com/v2/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sirvCredentials),
  });
  const data = await response.json();
  return data.token;
};
