import { Asset } from "./Asset";
import { useFiles } from "./hooks/useFiles";

export const Assets = () => {
  const files = useFiles(
    "files",
    "https://api.sirv.com/v2/files/readdir?dirname=/Images/bdkami/bd"
  );

  return (
    <>
      <h2 className="heading">&#10084;Нашите моменти&#10084;</h2>

      <div className="collection" style={{ zIndex: 1 }}>
        {!!files.length &&
          files.map((file) => (
            <Asset
              key={file.filename}
              isVideo={file.contentType?.includes("video")}
              name={file.filename}
              src={`${process.env.REACT_APP_COLLECTION_URL}/${file.filename}`}
            />
          ))}
      </div>
    </>
  );
};
