import { useRef } from "react";
import { useIsVisible } from "./hooks/useIsVisible";

export const Ending = (props) => {
  const { audioRef } = props;
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  return (
    <div ref={ref} style={{ margin: "150px 0" }}>
      {isVisible && (
        <div>
          <h2 className="heading">Пожелание към теб</h2>
          <div className="two-cols">
            <div>
              <div style={{ position: "relative" }}>
                <p style={{ fontSize: "3rem", textAlign: "center" }}>
                  Da birthday girl
                </p>
                <div
                  style={{
                    transform: "rotate(240deg) translateY(50%)",
                    width: "30px",
                    position: "absolute",
                    right: "0",
                    top: "50%",
                  }}
                >
                  <span>&#8604;</span>
                </div>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/bdgirl.jpg`}
                alt="bdgirl"
                style={{ width: "500px", height: "700px" }}
              />
            </div>

            <img src="hearts.gif" alt="hearts" />

            <div>
              <div style={{ position: "relative" }}>
                <p style={{ fontSize: "3rem", textAlign: "center" }}>
                  Her love dove
                </p>
                <div
                  style={{
                    transform: "rotate(240deg) translateY(50%)",
                    width: "30px",
                    position: "absolute",
                    right: "150px",
                    top: "50%",
                  }}
                >
                  <span>&#8604;</span>
                </div>
              </div>

              <video
                style={{ width: "700px", height: "700px" }}
                controls
                src={`https://rdkami.sirv.com/Images/bdkami-2/me.mp4`}
                onClick={() => {
                  audioRef.current.pause();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
